.top-topics {
    &.row {
        gap: $grid-gutter-width 0;
    }

    .col,
    [class*="col-"] {
        display: flex;
        flex-direction: column;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: $color__white;
        border: 1px solid $color__gray;
        border-radius: 4px;
        transition: background-color .2s linear, border-color .2s linear;

        &:not(.top-topics__item--has-image) {
            body:not([data-editmode='true']) & {
                color: $color__font--negative;
                background-color: $color__primary;

                &.top-topics__item--has-link {
                    @include hover-focus-within {
                        background-color: $color__primary--hover;
                    }
                }

                .top-topics {
                    &__caption {
                        justify-content: flex-end;
                        padding-top: 48px;

                        h2, h3, h4, h5, h6,
                        .h2, .h3, .h4, .h5, .h6 {
                            color: $color__font--negative;
                        }

                        p {
                            &[aria-hidden='true'] {
                                img {
                                    filter: $filter__white;
                                }
                            }
                        }

                        a {
                            color: $color__font--negative;
                        }
                    }
                }
            }
        }

        &.top-topics__item--has-image {
            &.top-topics__item--has-link {
                @include hover-focus-within {
                    border-color: $color__font;

                    .top-topics {
                        &__image {
                            border-color: $color__font;
                        }
                    }
                }
            }

            .top-topics {
                &__caption {
                    a {
                        color: $color__font;
                    }

                    p {
                        &[aria-hidden='true'] {
                            margin-top: auto;
                        }
                    }
                }
            }
        }

        &.top-topics__item--has-link {
            @include hover-focus-within {
                .top-topics {
                    &__caption {
                        a {
                            text-decoration: underline;
                            text-decoration-thickness: .08em;
                        }

                        p {
                            &[aria-hidden='true'] {
                                img {
                                    transform: translateX(4px);
                                }
                            }
                        }
                    }
                }
            }

            .top-topics {
                &__caption {
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    &__image {
        border-bottom: 1px solid $color__gray;
        transition: border-color .2s linear;

        img {
            border-radius: 4px 4px 0 0;
        }
    }

    &__caption {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 32px 32px 48px;

        p {
            &[aria-hidden='true'] {
                img {
                    transition: transform .2s ease-in-out;
                }
            }
        }
    }
}
